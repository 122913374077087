import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import moment from "moment";

const PagePlayerScreen = () => {
  const { id_player } = useParams();
  const [active, setACtive] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currQ, setCurrQ] = useState(null);
  const [count, setCount] = useState(0);

  const [game, setGame] = useState(null);
  const [team, setTeam] = useState("");

  useEffect(() => {
    if (id_player !== undefined) {
      const interval = setInterval(() => {
        setCount(count + 1);
        handleCheckUpdate();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [count, id_player]);

  useEffect(() => {
    getGame();
  }, []);

  useEffect(() => {
    if (game !== null) {
      let player = game.team_player?.find(
        (item) => item.player[0].id_player === id_player
      );
      setTeam(player?.team);
    }
  }, [game]);

  const getGame = () => {
    axios
      .get(CONFIG.URL + "/game/player/1", {
        headers: {
          token: CONFIG.TOKEN_LOGIN,
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setGame(response.data?.data[0]);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleCheckUpdate = async () => {
    const response = await axios.get(CONFIG.URL + "/game/current/question/1");

    let localTime = Cookies.get("lct_pq" + id_player);
    if (localTime && localTime !== undefined && localTime !== "undefined") {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        fetchCurrentQuestion();
        Cookies.set("lct_pq" + id_player, response?.data?.last_update);
      }
    } else {
      Cookies.set("lct_pq" + id_player, response?.data?.last_update);
      fetchCurrentQuestion();
    }
  };

  const fetchCurrentQuestion = () => {
    axios
      .get(CONFIG.URL + `/game/question/1?limit=1`)
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let arrQ = response.data?.data[0];
          setCurrQ(arrQ);
          if (arrQ.is_active === "1") {
            setACtive(true);
          }
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleAnswer = () => {
    if (loading) return;

    setACtive(false);
    setLoading(true);

    const payload = {
      id_game: 1,
      id_player: id_player,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/game/bell", form_data)

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setACtive(false);
          } else {
            setACtive(true);
          }
        })
        .catch(function (error) {
          setLoading(false);
          setACtive(true);
        });
    } catch (error) {
      setLoading(false);
      setACtive(true);
    }
  };

  const checkActive = () => {
    if (active & (currQ?.is_active === "1")) {
      return true;
    } else {
      return false;
    }
  };

  const renderTeam = () => {};

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-black"></div>
      )}
      <div
        className="font-bebas absolute top-0 left-0 bg-white w-full h-screen flex justify-center flex-col items-center p-4"
        style={{
          // backgroundImage: `url('${props?.setting?.background}')`,
          backgroundImage: `url('https://rblrally2024-api.eventqiu.com/media/media-n1D7ZVrsfw4yByn.jpg')`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-black text-white bg-opacity-50 backdrop-blur-md w-[50%] rounded-md px-4 py-4 text-center">
          <div className="tracking-[3px] mx-auto px-5 py-2 font-semibold text-4xl border-b border-black pb-5">
            {team}
          </div>

          <button
            disabled={!checkActive()}
            onClick={() => handleAnswer()}
            className={`${
              checkActive()
                ? "bg-green-600 cursor-pointer"
                : "bg-gray-500 cursor-not-allowed"
            } tracking-[3px] bg-opacity-70 backdrop-blur-md rounded-md w-full h-[50vh] flex justify-center items-center mx-auto px-5 py-2 text-white font-semibold text-6xl  mt-5`}
          >
            ANSWER
          </button>
        </div>
      </div>
    </>
  );
};

export default PagePlayerScreen;
