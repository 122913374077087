const getBaseUrl = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin;

    var apiUrl = `${base_url}/limeeasy/jeopardy2024-api/api`;
    return apiUrl;
  } else {
    return "";
  }
};

const getBaseUrlMedia = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin;

    var apiUrl = `${base_url}/limeeasy/jeopardy2024-api/media`;
    return apiUrl;
  } else {
    return "";
  }
};

const getBaseUrlRoot = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin;
    var apiUrl = `${base_url}/limeeasy/limeeasy-fe`;
    return apiUrl;
  } else {
    return "";
  }
};

// const CONFIG = {
//   URL: getBaseUrl(),
//   URL_MEDIA: getBaseUrlMedia(),
//   URL_ROOT: getBaseUrlRoot(),
// };

const CONFIG = {
  URL: "https://jeopardy2024-api.eventqiu.com/api",
  URL_MEDIA: "https://jeopardy2024-api.eventqiu.com/media",
  URL_ROOT: "https://limeeasy.eventqiu.com",
  TOKEN_LOGIN: "eyJpZF9hZG1pbiI6IjE2IiwidG9rZW4iOiJqQVFENGM3SGFOZkVIek15c3RGWDE2Iiwicm9sZSI6InN0YWZmIn0.ItYr_F8OeavUo7P-rpTTdCqfEgZ8H5_t0SadUMw2jck"
};

// URL: "https://prestige-api.eventqiu.com/api",
// URL: "http://192.168.100.114/prestige/prestige-api/api",
export default CONFIG;
