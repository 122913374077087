import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import CONFIG from "../../constanta/config";


const ScreenQuestion = ({
  selected,
  initialCountdown,
  handleClose,
  id_game,
}) => {
  const [loading, setLoading] = useState(false);
  const [boxTimer, setBoxTimer] = useState([
    { id: 1, active: true },
    { id: 2, active: true },
    { id: 3, active: true },
    { id: 4, active: true },
    { id: 5, active: true },
    { id: 6, active: true },
    { id: 7, active: true },
    { id: 8, active: true },
    { id: 9, active: true },
    { id: 10, active: true },
    { id: 11, active: true },
    { id: 12, active: true },
    { id: 13, active: true },
    { id: 14, active: true },
    { id: 15, active: true },
    { id: 16, active: true },
    { id: 17, active: true },
    { id: 18, active: true },
    { id: 19, active: true },
  ]);

  const idTimer = [
    [1, 19],
    [2, 18],
    [3, 17],
    [4, 16],
    [5, 15],
    [6, 14],
    [7, 13],
    [8, 12],
    [9, 11],
    [10],
  ];

  const [countdown, setCountdown] = useState(initialCountdown);
  const [listbell, setListbell] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer);
          // You can perform any action when countdown reaches 0 here
        } else {
          let currentCountdown = prevCountdown;

          let _boxTimer = [...boxTimer];

          let i = 0;
          while (i < idTimer[initialCountdown - currentCountdown].length) {
            // eslint-disable-next-line no-loop-func
            let item = _boxTimer.find(
              // eslint-disable-next-line no-loop-func
              (v) => v.id === idTimer[initialCountdown - currentCountdown][i]
            );

            if (item !== undefined) {
              item.active = false;
            }
            i++;
          }

          setBoxTimer(_boxTimer);

          if (id_game !== undefined) {
            getBell();
          }

          return currentCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialCountdown]);

  const getBell = () => {
    axios
      .get(CONFIG.URL + "/game/bell/" + id_game)
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setListbell(response?.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleCorrectAnswer = (item) => {
    if (loading) return;

    setLoading(true);

    const payload = {
      id_game: id_game,
      id_question: selected?.question?.id,
      id_player: item?.id_player || "",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/game/player", form_data, {
          headers: {
            token: CONFIG.TOKEN_LOGIN,
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            handleClose();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const onClose = () => {
    handleCorrectAnswer(null);
  }

  return (
    <>
      {/* SCREEN QUESTION */}
      <div className="border-4  border-[#83817e] bg-[#011aa0] w-full h-full flex justify-between flex-col items-center p-4">
        <div className=" bg-black text-white tracking-[3px] bg-opacity-50 backdrop-blur-md w-max mx-auto px-10 py-2 rounded-md font-bold text-xl">
          {selected?.name} - ${selected?.question?.score}
        </div>

        <div className="bg-black bg-opacity-50 backdrop-blur-md w-[90%] rounded-md px-4 py-4 text-center">
          <div className="mx-auto px-5 py-2 text-white  font-semibold text-7xl leading-[1.3]">
            {selected?.question?.question}
          </div>
        </div>

        <div>
          <div className="mb-4 w-full flex justify-center items-center space-x-2 flex-wrap space-y-2">
            {listbell?.map((item, key) => {
              return (
                <button
                  key={key}
                  onClick={() => handleCorrectAnswer(item)}
                  className=" bg-green-700 focus:bg-yellow-500 py-2 px-6 rounded-md text-white text-xl tracking-[2px]"
                >
                  {item.team}
                </button>
              );
            })}

            <button
              onClick={() => onClose()}
              className="bg-red-700 py-2 px-6 rounded-md text-white text-xl tracking-[2px]"
            >
              None Correct
            </button>
          </div>
          <div className="flex items-center justify-center space-x-1 bg-[#83817e]  w-max  p-1">
            {boxTimer?.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`${
                    item.active ? "bg-red-700" : "bg-black"
                  } h-5 w-12 rounded-sm`}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScreenQuestion;
