import { useEffect, useState } from "react";

const ScreenFormSetup = ({ selected, initialCountdown, handleNoneCorrect }) => {
  
  return (
    <>
      {/* SCREEN QUESTION */}
      <div
        className="border-4  border-[#83817e] bg-[#011aa0] w-full h-full flex justify-between flex-col items-center p-4"
        
      >
       

        <div className="bg-black bg-opacity-50 backdrop-blur-md w-[90%] rounded-md px-4 py-4 text-center">
          <div className="mx-auto px-5 py-2 text-white  font-semibold text-7xl leading-[1.3]">
            JEOPARDY
          </div>
        </div>

        <div>
          <div className="mb-4 w-full flex justify-center items-center">
            <button
              onClick={() => handleNoneCorrect()}
              className="bg-red-700 py-2 px-6 rounded-md text-white text-xl tracking-[2px]"
            >
              None Correct
            </button>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default ScreenFormSetup;
