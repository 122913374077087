import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import { useQuery } from "../../hook/useQuery";

const NewGameForm = (props) => {
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);
  const { register, control, handleSubmit, errors } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "dynamicFields",
  });

  useEffect(() => {
    handleAddTeam();
    handleAddTeam();
    handleAddTeam();
  }, []);

  const onSubmit = (data) => {
    // debugger;
    // return;
    if (loading) return;

    setMsg("");

    let arr = [];

    let i = 0;
    while (i < data.dynamicFields.length) {
      let obj = {
        team: data.dynamicFields[i].team || "",
      };

      if (obj.team?.trim() !== "" ) {
        arr.push(obj);
      }

      i++;
    }


    if (arr.length < 3) {
      setMsg("Please complete all team");
      return;
    }
    
   

   setLoading(true);
    const payload = {
      team_1: arr[0].team,
      team_2: arr[1].team,
      team_3: arr[2].team
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/game/team", form_data, {
          headers: {
            token: CONFIG.TOKEN_LOGIN,
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddTeam = () => {
    append({
      team: "",
    });
  };

  return (
    <div className="fixed bg-black bg-opacity-50 h-screen w-screen flex flex-col justify-center items-center">
      <div className="bg-white w-[80%] rounded-md py-4 px-4 flex flex-col space-y-2 h-max">
        <div className="text-center font-bold mb-0">
          <div className="text-2xl text-center">New Game</div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-2  bg-white rounded-md w-full"
        >
          <div className="grid grid-cols-3 gap-5">
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="flex flex-col space-y-2 border px-2 py-2 rounded-md"
              >
                <div className="flex space-x-1">
                  <label className="text-black  font-bold w-[50px] flex-none">
                    Team:
                  </label>
                  <input
                    className="border-2 px-2 h-[38px] rounded w-full"
                    name={`team`}
                    {...register(`dynamicFields[${index}].team`)}
                  />
                </div>
              </div>
            ))}
          </div>

          {msg !== "" && (
            <div className="text-center font-bold text-red-500">{msg}</div>
          )}
          <div className="flex justify-center items-center space-x-2">
            <button
              type="button"
              onClick={() => props.onClose()}
              className={`w-max px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`w-max px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Create New Game"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewGameForm;
