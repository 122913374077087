import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { QrReader } from "react-qr-reader";

const PopupSearch = (props) => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);


  useEffect(() => {
    if (watch("search").length > 1) {
      let arr = [...users];

      let filter = [];

      filter = arr.filter((item) =>
        item.name.toLowerCase().includes(watch("search").toLowerCase())
      );

      setFilter(filter);
    } else {
      setFilter([]);
    }
  }, [watch("search")]);

  const handleSelect = (item) => {
    props.onSuccess(item.id);
  };

  return (
    <>
      <form className="w-full">
        <div className="absolute z-20 top-0 left-0 w-screen h-screen bg-[#000000b3]"></div>
        <div className="absolute py-20 z-50  top-0 left-0 w-screen h-screen flex flex-col justify-between items-center">
          <div className="flex flex-col space-y-2">
            <div className=" text-white text-center font-bold text-2xl uppercase">
              {props.gate}
            </div>
            <div className=" text-white text-center font-semibold">
              Please search name
            </div>
          </div>
          <div className="h-2/3 flex flex-col w-full px-10 max-w-lg">
            <div className="text-white mb-1">Name:</div>
            <input
              autoFocus
              {...register("search")}
              autocomplete="off"
              className="w-full mb-5 min-w-[160px] px-2 py-2 bg-black border-2 border-white rounded-lg text-white"
            />
            {filter.length === 0 && (
              <div className="text-white text-center">User not found.</div>
            )}
            <div className="text-white flex flex-col flex-grow overflow-y-auto">
              {filter?.map((item, key) => {
                return (
                  <div
                    onClick={() => handleSelect(item)}
                    key={key}
                    className="cursor-pointer py-3 border-b border-b-white"
                  >
                    <div className="flex flex-col">
                      <span className="text-lg"> {item.name}</span>
                      <span className="text-sm text-gray-300">
                        {item?.email}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" w-full flex justify-center items-center ">
            <button
              onClick={() => props.onClose()}
              className="min-w-[160px] px-10 py-2 bg-black border-2 border-red-500 rounded-lg text-red-500 font-bold"
            >
              GO HOME
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default PopupSearch;
