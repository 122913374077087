import { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../constanta/config";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";

const PageAnswerScreen = () => {
  const [currQ, setCurrQ] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
     
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      handleCheckUpdate();
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  const handleCheckUpdate = async () => {
    const response = await axios.get(CONFIG.URL + "/game/current/question/1");

    let localTime = Cookies.get("lct_aq");
    if (localTime && localTime !== undefined && localTime !== "undefined") {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        fetchCurrentQuestion();
        Cookies.set("lct_aq", response?.data?.last_update);
      }
    } else {
      Cookies.set("lct_aq", response?.data?.last_update);
      fetchCurrentQuestion();
    }
  };

  const fetchCurrentQuestion = () => {
    axios
      .get(CONFIG.URL + `/game/question/1?limit=1`)
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let arrQ = response.data?.data[0];
          setCurrQ(arrQ);
        } else {
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      {/* SCREEN QUESTION */}
      <div
        className="font-bebas absolute top-0 left-0 bg-white w-full h-screen flex justify-center flex-col items-center p-4"
        style={{
          // backgroundImage: `url('${props?.setting?.background}')`,
          backgroundImage: `url('https://rblrally2024-api.eventqiu.com/media/media-n1D7ZVrsfw4yByn.jpg')`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-black text-white bg-opacity-50 backdrop-blur-md w-[90%] rounded-md px-4 py-4 text-center">
          {currQ !== null && currQ.is_active === "1" && (
            <>
              {" "}
              <div className="mx-auto px-5 py-2  text-3xl mt-5">Question:</div>
              <div className="mx-auto px-5 py-2 text-4xl border-b border-white pb-5">
                {currQ.question}
              </div>
              <div className="mx-auto px-5 py-2  font-semibold text-3xl mt-5">
                Answer:
              </div>
              <div className="mx-auto px-5 py-2  font-semibold text-6xl">
                {currQ.answer}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PageAnswerScreen;
