import { useEffect, useState } from "react";
import ScreenQuestion from "./question";

const BoxCategory = (props) => {
  const { data, handleChoose } = props;

  const handleOnClik = (val, q) => {
    let obj = {
      id: val.id,
      name: val.name,
      question: q,
    };

    handleChoose(obj);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="h-[10vh] bg-[#011aa0] flex items-center justify-center ">
        <label className="uppercase font-bold text-white text-center text-2xl px-4 leading-6">
          {data.name}
        </label>
      </div>
      <div className="flex flex-col space-y-2 mt-2 h-full">
        {data.questions?.map((item, key) => {
          return (
            <div
              key={key}
              onClick={() => handleOnClik(data, item)}
              className="cursor-pointer h-full bg-[#011aa0] hover:bg-[#00116e] flex items-center justify-center py-1"
            >
              <label className="uppercase text-[#ecaa51] text-center text-6xl font-black">
                {item.active ? `$${item.reward}` : ""}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PageSetupGame = (props) => {
  const [selected, setSelected] = useState(null);

  return (
    <>
      {/* SCREEN LIST */}
      <div className="bg-[#403e40] flex justify-center items-start w-full h-screen">
        {/* <div className="w-12 h-full bg-[#aee9ee] border-8 border-[#377cd1]">
         
        </div> */}
        <div className="flex flex-col bg-[#403e40] h-full w-full">
          <div className="flex flex-col space-y-2 w-full bg-black pt-2 px-2">
            <div className="w-full h-1 bg-[#836332]"></div>
            <div className="w-full h-1 bg-[#836332]"></div>
            <div className="w-full h-1 bg-[#836332]"></div>
          </div>

          <div className="flex w-full bg-black py-2 px-2">
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
          </div>
          <div className="px-10 py-4 bg-[#323034] h-full w-full">
            <ScreenQuestion selected={selected} initialCountdown={10} />
          </div>
          <div className="flex w-full bg-black py-2 px-2">
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSetupGame;
