import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";
import helper from "../../utils/helper";
import axios from "axios";
import CONFIG from "../../constanta/config";
import NewGameForm from "./new-game";

const PageHome = (props) => {
  const query = useQuery();
  const [listGame, setListGame] = useState();
  const [selectedGame, setSelectedGame] = useState(null);
  const [showNewGame, setShowNewGame] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // if (Cookies.get("token") === undefined) {
    //   window.location.href = "./login";
    // } 
  }, []);

  const logout = () => {
    Cookies.remove("token");
    window.location.href = "./login";
  };

  const getGames = () => {
    axios
      .get(CONFIG.URL + "/game/player", {
        headers: {
          token: CONFIG.TOKEN_LOGIN,
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setListGame(response.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const renderPlayer = (player) => {
    return (
      <div className="flex justify-center items-center space-x-2 w-full">
        {player?.map((subitem, subkey) => {
          return (
            <a
              key={subkey}
              href={`./player-screen/${selectedGame?.id_game}/${subitem.id_player}/${subitem.player_name}`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              {subitem.player_name}
            </a>
          );
        })}
      </div>
    );
  };

  const handleSuccessCreateGame = () => {
    navigate("/game-screen");
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center  bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url('${helper.getBackground(
            props?.setting?.background
          )}')`,
        }}
      >
        {selectedGame !== null && (
          <div className="top-0 py-10 left-0 fixed bg-black bg-opacity-50 h-screen w-screen flex flex-col justify-start items-center">
            <div className="w-[70%] bg-white rounded-md py-6 px-10 flex flex-col space-y-2 overflow-y-auto">
              <div className="font-bold text-center uppercase">
                {selectedGame?.game}
              </div>
              <div className="font-bold text-center my-2">HOST</div>
              <a
                href={`./game-screen/${selectedGame?.id_game}`}
                className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
              >
                Game Screen
              </a>
              <a
                href={`./answer-screen/${selectedGame?.id_game}`}
                className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
              >
                Answer Screen
              </a>

              <div className="font-bold text-center my-2">PLAYER</div>

              {selectedGame?.team_player?.map((item, key) => {
                return (
                  <div key={key}>
                    <div className="font-bold text-center my-2">
                      {" "}
                      TEAM : {item?.team}
                    </div>

                    <div className="w-full"> {renderPlayer(item?.player)}</div>
                  </div>
                );
              })}

              <div className="w-full">
                <div className="border border-black w-full my-5"></div>
              </div>

              <button
                onClick={() => setSelectedGame(null)}
                className="min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center"
              >
                CLOSE
              </button>
            </div>
          </div>
        )}

        {showNewGame && (
          <NewGameForm
            onSuccess={() => handleSuccessCreateGame()}
            onClose={() => setShowNewGame(false)}
          />
        )}

        <div className="flex flex-col justify-center items-center pb-20">
          <div className="mb-10  flex flex-col justify-center items-center space-y-2.5 w-full">



            <button
              onClick={() => setShowNewGame(true)}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              NEW GAME
            </button>
            <div className="w-full">
              <div className="border border-white w-full my-5"></div>
            </div>

            {/* <button
              onClick={() => logout()}
              className={`mt-10 min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
            >
              LOGOUT
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHome;
