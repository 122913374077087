import { useEffect, useState } from "react";
import ScreenQuestion from "./question";
import axios from "axios";
import CONFIG from "../../constanta/config";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import ScreenFinishGame from "./finish_game";

const BoxCategory = (props) => {
  const { data, handleChoose } = props;

  const handleOnClik = (val, q) => {
    let obj = {
      id: val.id,
      name: val.name,
      question: q,
    };

    handleChoose(obj);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="h-[10vh] bg-[#011aa0] flex items-center justify-center ">
        <label className="uppercase font-bold text-white text-center text-2xl px-4 leading-6">
          {data.name}
        </label>
      </div>
      <div className="flex flex-col space-y-2 mt-2 h-full">
        {data.questions?.map((item, key) => {
          return (
            <div
              key={key}
              onClick={() => handleOnClik(data, item)}
              className="cursor-pointer h-full bg-[#011aa0] hover:bg-[#00116e] flex items-center justify-center py-1"
            >
              <label className="uppercase text-[#ecaa51] text-center text-6xl font-black">
                {item.active ? `$${item.score}` : <span>&nbsp;</span>}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PageGameScreen = (props) => {
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [score, setScore] = useState([]);
  const [game, setGame] = useState(null);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
     
    }

    getQuestion();
    getGame();
  }, []);

  useEffect(() => {
    getScore();
  }, []);

  function groupObjectsBy(list, key) {
    return list.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push({
        active: true,
        ...obj,
      });
      return acc;
    }, {});
  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getGame = () => {
    axios
      .get(CONFIG.URL + "/game/player/1", {
        headers: {
          token: CONFIG.TOKEN_LOGIN,
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setGame(response.data?.data[0]);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const getQuestion = () => {
    axios
      .get(CONFIG.URL + "/question?filter=all&limit=10000")
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let arrQ = response.data?.data;

          const groupedObjects = groupObjectsBy(arrQ, "id_category");

          let arr = Object.values(groupedObjects);
          arr = arr.map((item) => {
            let rnd = shuffleArray(item);
            const removedObjects = rnd.splice(0, Math.min(5, rnd.length));

            return {
              id: item[0].id_category,
              name: item[0].category,
              questions: removedObjects,
            };
          });

          arr.forEach(category => {
            category.questions.sort((a, b) => parseInt(a.score) - parseInt(b.score));
          });

          setListCategory(arr);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleChoose = (val) => {
    if (loading) return;

    setLoading(true);

    const payload = {
      id_game: 1,
      id_question: val.question.id,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/game/question", form_data, {
          headers: {
            token: CONFIG.TOKEN_LOGIN,
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setSelected(val);

            let _list = [...listCategory];
            let v = _list.find((item) => item.id === val.id);
            if (v !== undefined) {
              let quest = [...v.questions];
              let q = quest.find((item) => item.id === val.question.id);
              q.active = false;

              v.questions = quest;

              setListCategory(_list);
            }
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const getScore = () => {
    axios
      .get(CONFIG.URL + "/game/score/1")
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setScore(response.data?.data[0] || []);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleClose = () => {
    setSelected(null);
    getScore();
    getGame();
    handleFinishGame();
  };

  const handleFinishGame = () => {
    let arr = [...listCategory];
    arr = arr.map((item) => {
      let arrQ = item.questions.filter((q) => q.active === true);

      return {
        active: arrQ.length === 0 ? false : true,
      };
    });

    arr = arr.filter((q) => q.active === true);

    if (arr.length === 0) {
      setFinish(true);
      // handleSetGameEnd();
    }
  };

  const handleSetGameEnd = (item) => {
    if (loading) return;

    setLoading(true);

    const payload = {
      id_game: 1,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/game/end", form_data, {
          headers: {
            token: CONFIG.TOKEN_LOGIN,
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const renderScore = (itemPlayer) => {
    if (score?.score_player?.length > 0) {
      let pl = score.score_player.find(
        (item) => item.id_player === itemPlayer.id_player
      );
      if (pl !== undefined) {
        return <span className="text-[#ecaa51]">{pl.score}</span>;
      } else {
        return <span className="text-[#ecaa51]">0</span>;
      }
    } else {
      return <span className="text-[#ecaa51]">0</span>;
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-transparent"></div>
      )}
      {/* SCREEN LIST */}
      <div className="font-bebas bg-[#403e40] flex justify-center items-start w-full h-screen">
        {/* <div className="w-12 h-full bg-[#aee9ee] border-8 border-[#377cd1]">
         
        </div> */}
        <div className="flex flex-col bg-[#403e40] h-full w-full">
          <div className="flex flex-col space-y-2 w-full bg-black pt-2 px-2">
            <div className="w-full h-1 bg-[#836332]"></div>
            <div className="w-full h-1 bg-[#836332]"></div>
            <div className="w-full h-1 bg-[#836332]"></div>
          </div>

          <div className="flex w-full bg-black py-2 px-2">
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[5%] h-5 bg-[#47bcf7] border-2 border-black"></div>
          </div>
          <div className="px-10 py-4 bg-[#323034] h-full w-full">
            {finish && <ScreenFinishGame score={score} />}

            {!finish && (
              <>
                {selected !== null ? (
                  <ScreenQuestion
                    id_game={1}
                    selected={selected}
                    initialCountdown={10}
                    handleClose={() => handleClose()}
                  />
                ) : (
                  <>
                    <div className="flex flex-col h-full border-4 bg-black border-[#83817e] w-full">
                      <div
                        className={`h-full bg-black grid gap-2 w-full`}
                        style={{
                          gridTemplateColumns: `repeat(${listCategory.length}, minmax(0, 1fr))`,
                        }}
                      >
                        {listCategory?.map((item, key) => {
                          return (
                            <BoxCategory
                              key={key}
                              data={item}
                              handleChoose={(val) => handleChoose(val)}
                            />
                          );
                        })}
                      </div>
                      <div className="flex h-max bg-[#011aa0] w-full mt-2 rounded-md py-2 px-2">
                        <div className="mx-auto rounded-full h-full py-2 w-max bg-black text-white flex flex-wrap-reverse items-center justify-center px-10">
                          {game?.team_player?.map((item, key) => {
                            return (
                              <div
                                key={key}
                                className="font-bold text-2xl px-4 border-x"
                              >
                                <span
                                  class={`${
                                    item.player[0].is_correct === "0"
                                      ? "text-[#ecaa51]"
                                      : "text-green-500"
                                  }`}
                                >
                                  {item.team}
                                </span>
                                : {renderScore(item.player[0])}
                              </div>
                            );
                          })}
                        </div>
                        <button
                          onClick={() => setFinish(true)}
                          className=" bg-red-700 flex-none py-2 px-6 rounded-md text-white text-xl tracking-[2px]"
                        >
                          END
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="flex w-full bg-black py-2 px-2">
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
            <div className="w-[10%] h-5 bg-[#47bcf7] border-2 border-black"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageGameScreen;
