import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import CONFIG from "../../constanta/config";

const ScreenFinishGame = (props) => {

  function findHighestNumber(arr) {
    // Extract the 'number' property from each object and store it in a new array
    const numbersArray = arr.map(obj => obj.score);
    
    // Find the maximum value in the numbersArray
    const highestNumber = Math.max(...numbersArray);
    return highestNumber.toString();
  }

  return (
    <>
      {/* SCREEN QUESTION */}
      <div className="border-4  border-[#83817e] bg-[#011aa0] w-full h-full flex justify-center space-y-4 flex-col items-center p-4">
        <div className=" bg-black text-white tracking-[3px] bg-opacity-50 backdrop-blur-md w-max mx-auto px-10 py-2 rounded-md font-bold text-xl">
          FINISH
        </div>

        <div className="bg-black justify-center flex  bg-opacity-50 backdrop-blur-md w-[90%] rounded-md px-4 py-4 text-center">
          {props?.score?.score_team?.map((item, key) => {
            return (
              <div
                key={key}
                className="px-5 py-2 text-white  font-semibold text-4xl leading-[1.3] border-x"
              >
                {/* <div>{item.team}</div> */}

                {props?.score?.score_player?.map((subitem, subkey) => {
                  if (subitem.team.toLowerCase() === item.team.toLowerCase()) {
                    return (
                      <div className="flex flex-col" key={subkey}>
                        <span className={`${findHighestNumber(props?.score?.score_player) === subitem.score ? 'text-green-500' : 'text-white'}`}>{subitem.team} </span>
                        <span className={`${findHighestNumber(props?.score?.score_player) === subitem.score ? 'text-green-500' : 'text-white'}`}>${subitem.score}</span>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>

        <a
          href="/"
          className=" bg-green-500 text-white tracking-[3px] bg-opacity-50 backdrop-blur-md w-max mx-auto px-10 py-2 rounded-md font-bold text-xl"
        >
          NEW GAME
        </a>
      </div>
    </>
  );
};

export default ScreenFinishGame;
